<template>
  <v-container fluid>
    <v-tabs
      fixed-tabs
      class="m-b-20"
      background-color="darkorange"
      dark
      v-model="changeTabListing"
    >
      <v-tab
        :href="`#basicDetail`"
        @click="
          filterByTab('basicDetail');
          $router.push(
            `/transporter/edit/${$route.params.id}/${$route.params._id}/${$route.params.country}/true`
          );
        "
      >
        Basic Details
      </v-tab>
      <v-tab
        :href="`#drivers`"
        @click="
          filterByTab('drivers');
          $router.push(
            `/transporter/driver/${$route.params.id}/${$route.params._id}/${$route.params.country}`
          );
        "
      >
        Drivers
      </v-tab>
    </v-tabs>

    <v-flex> </v-flex>
    <SelectCountry
      :open="selectCountryDialog"
      @closeSelectCountry="selectCountryDialog = false"
      :type="`/transporter/add/${this.$route.params._id}`"
    />
    <v-card raised v-if="showScreen" class="pt-3">
      <loading :active.sync="loading" />
      <v-card-title primary-title class="bg-clr">
        <v-btn fab flat icon class="nospace" @click.native="close">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <span class="headline"
          >&nbsp;Transporter - {{ transporterUsername }}</span
        >
      </v-card-title>

      <v-card-text class="pt-4">
        <v-layout style="border-top: 1px solid #efefef" class="p-16">
          <v-flex xs2 md4>
            <v-select
              v-model="searchTypes"
              :items="searchItems"
              flat
              label="Search Type"
              item-text="status"
              single-line
              class="pt-0 currencyTitle pppp"
              item-value="abbr"
              persistent-hint
              @change="searchByType()"
              height="10"
              return-object
              outline
            ></v-select>
          </v-flex>
          <v-flex xs2 md5 pr-3>
            <v-text-field
              height="55px"
              class="gap Bottom_2px"
              label="Search here.."
              outline
              :disabled="!searchTypes"
              single-line
              v-model="search.text"
              @keyup.delete="searchDriver"
              @keyup.enter="searchDriver"
              @click:append="searchDriver"
              append-icon="search"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md3 pr-3>
            <v-select
              label="Availablity"
              :items="availableStatus"
              class="pt-0"
              @change="searchDriverStatus()"
              style="padding-top: 12px !important"
              v-model="status"
              single-line
            />
          </v-flex>
          <v-flex xs2 md3 class="reset-btn">
            <v-card-actions class="justify-center">
              <v-btn
                class="reset-btn-color"
                flat
                prepend-icon
                color="orange darken-1"
                @click.native="resetSearch()"
              >
                Reset
                <v-icon color="orange">donut_large</v-icon>
              </v-btn>
            </v-card-actions>
          </v-flex>
          <v-flex pt-2>
            <v-btn
              v-permissions="'register-new-driver'"
              class="white--text pull--right"
              flat
              color="orange darken-1"
              @click="checkAdd()"
            >
              <v-icon>add</v-icon>&nbsp;Register New Driver
            </v-btn>
          </v-flex>
        </v-layout>
        <v-data-table
          id="driversTable"
          :headers="headers"
          hide-actions
          :items="items"
          :loading="loading"
        >
          <template slot="items" slot-scope="props">
            <td :class="[props.item.isDocumentsExpiry ? 'text-red' : '']">
              {{ props.item.driverId }}
              <activityLogs
                :type="'driver'"
                :id="props.item.driverId.toString()"
              />
            </td>
            <td>
              <img
                v-if="props.item.workingCountry == 'PAK'"
                src="../../assets/pakflag.png"
                height="20px"
              /><img
                v-if="props.item.workingCountry == 'KSA'"
                src="../../assets/ksaflag.png"
                height="20px"
              /><img
                v-if="props.item.workingCountry == 'UAE'"
                src="../../assets/Uaeflag.png"
                height="20px"
              />
              <img
                v-if="props.item.workingCountry == 'UAE LA'"
                src="../../assets/Uaeflag.png"
                height="20px"
                style="border-radius: 50%"
              />
              <img
                v-if="props.item.workingCountry == 'OMAN'"
                src="../../assets/omanflag.png"
                height="28px"
              />
              <br />{{ props.item.workingCountry }}
            </td>
            <td>
              {{ getJobType(props.item.jobType) || "Land" }}<br />
              {{
                props.item.jobType == "1" && props.item.borderType
                  ? "(" + getBorderType(props.item.borderType) + ")"
                  : ""
              }}
            </td>
            <td
              v-if="props.item.firstName != null && props.item.lastName != null"
            >
              {{ props.item.firstName + " " + props.item.lastName }}<br />
            </td>

            <td v-if="!props.item.truckPrefix && props.item.truckNumber">
              {{ props.item.truckNumber }}
            </td>
            <td v-else-if="props.item.truckPrefix == 'Others'">
              {{ props.item.truckNumber }}
            </td>
            <td v-else-if="props.item.truckNumber && props.item.truckPrefix">
              {{ props.item.truckPrefix }}-{{ props.item.truckNumber }}
            </td>
            <td v-else-if="props.item.truckNumber">-</td>
            <td v-else>-</td>
            <td v-if="props.item.subTruckType">
              {{ getText(subTruck, props.item.subTruckType) }}
            </td>
            <td v-else>-</td>
            <td v-if="props.item.ownerType">
              {{ props.item.ownerType.toString() || "Owner" }}
            </td>
            <td v-else>-</td>
            <td v-if="props.item.deviceType == 'ANDROID'">DRIVER APP</td>
            <td v-else>{{ props.item.deviceType || "-" }}</td>

            <td
              v-if="
                props.item.phoneNo != null && props.item.countryCode != null
              "
            >
              <v-layout style="align-items: center" justify-center>
                {{ props.item.countryCode + "-" + props.item.phoneNo }}

                <a
                  style="margin-left: 8px; margin-top: -8px"
                  v-if="props.item.isChatAvailable"
                  v-permissions="'drivers/true'"
                  flat
                  prepend-icon
                  target="_blank"
                  v-bind:href="
                    'https://test-support.trukkin.com/#!/messages?key=' +
                    t +
                    '&driverId=' +
                    props.item._id
                  "
                  title="WhatsApp"
                >
                  <img
                    src="../../assets/whatsApp.png"
                    style="margin-top: 8px"
                    height="25"
                    width="24"
                  />
                </a>
                <a
                  style="margin-left: 8px; margin-top: -8px"
                  v-if="!props.item.isChatAvailable"
                  v-permissions="'drivers/true'"
                  flat
                  prepend-icon
                  target="_blank"
                  title="No message present."
                  aria-disabled="true"
                >
                  <img
                    src="../../assets/disableWhatApp.png"
                    style="margin-top: 8px"
                    height="25"
                    width="24"
                  />
                </a>
              </v-layout>
            </td>
            <td v-else>-</td>
            <td v-if="!props.item.availabilityStatus">
              <v-tooltip
                top
                class="milestone"
                content-class="tooltip-op"
                color="orange"
              >
                <template slot="activator">Assigned</template>
                <span class="tooltip"
                  >Assignment Id : {{ props.item.assignId }}</span
                >
              </v-tooltip>
            </td>
            <td v-if="props.item.availabilityStatus">Free</td>

            <td>
              <span v-if="props.item.accountVerified == true">Verified</span>
              <span v-if="props.item.accountVerified == false"
                >Not Verified</span
              >
            </td>
            <td class>
              <button
                v-permissions="'view-profile-driver'"
                @click="
                  goToUserProfile(
                    props.item.driverId,
                    props.item.workingCountry
                  )
                "
                target="_blank"
                class="track-ass-btn my-1"
              >
                Details
              </button>

              <button
                v-permissions="'view-profile-driver'"
                v-if="props.item.accountVerified == false"
                @click="
                  verified(props.item.driverId, 'true', props.item.isBlocked)
                "
                target="_blank"
                class="view-ass-btn"
              >
                Verify
              </button>
              <button
                @click="
                  verified(
                    props.item.driverId,
                    props.item.accountVerified.toString(),
                    'false'
                  )
                "
                v-if="props.item.isBlocked"
                class="track-ass-btn mx-1 yellow-button"
                title="Unblock Customer"
              >
                Unblock
              </button>

              <button
                @click="
                  verified(
                    props.item.driverId,
                    props.item.accountVerified.toString(),
                    'true'
                  )
                "
                v-if="!props.item.isBlocked"
                class="track-ass-btn mx-1 red-button"
                title="Block Customer"
              >
                Block
              </button>
            </td>
          </template>
          <v-alert
            slot="no-results"
            outline
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
        <span class="page-count-span totalpages"
          >Total Pages - {{ totalPages }}</span
        >
        <PaginationButtons
          :url="`/dmsAdmin/transporterDriver`"
          :payload="payload"
          :callback="callback"
          :componentKey="componentKey"
          v-on:pagination-payload="paginationPayload"
        />
      </v-card-text>
      <success-dialog
        :content="message"
        :show="success"
        :onclose="closeMessage"
      />
      <v-snackbar
        :timeout="6000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card>
  </v-container>
</template>

<script>
import SelectCountry from "../Common/SelectCountry";
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import { transporterDriverList } from "@/constants/datatable-headers.js";
import Swal from "sweetalert2";
import activityLogs from "../Common/activityLogs";
import ChargesPopUp from "@/components/Pop-ups/ChargesPopUp";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import DriverProfileForm from "@/components/Drivers/DriverProfileForm";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import { StorageKeys, JobTypes, TruckTypes } from "../../constants/constants";
import Loading from "vue-loading-overlay";
import { transporter } from "../../constants/api-urls";
export default {
  created() {
    this.checkPermission("drivers");
    this.getTransporter();
    this.$eventBus.$on("transporterDriver", (value) => {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
        this.componentKey += 1;
      }
    });
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
      this.payload = {
        skip: "0",
        limit: "15",
        transporterId: this.$route.params._id,
        workingCountry: this.$route.params.country,
      };
      this.searchItems = [
        { status: "Driver Name", abbr: "driverName" },
        { status: "Driver ID", abbr: "driverId" },
      ];
      if (workingCountry.length === 1 && workingCountry.indexOf("PAK") >= 0) {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
        ];
      }
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "transporter-driver-detail") {
        this.$router.push(`/transporter`);
      }
      this.workingCountry = JSON.stringify(val);
      this.loading = true;
      this.search.active = false;
      this.search.text = "";
      this.searchTypes = "";
      this.subTruckType = "";
      (this.status = ""), (this.jobType = "");
      this.preferredLocation = [];
      this.payload = {
        skip: "0",
        limit: "15",
        transporterId: this.$route.params._id,
        workingCountry: this.$route.params.country,
      };
      localStorage.removeItem("searchDriverPreferredLocation");
      localStorage.removeItem("searchDriverStatus");
      localStorage.removeItem("searchDriverType");
      localStorage.removeItem("searchTransporterDrivertext");
      localStorage.removeItem("DriverJobType");
      localStorage.removeItem("subTruckType");
      this.searchItems = [
        { status: "Driver Name", abbr: "driverName" },
        { status: "Driver ID", abbr: "driverId" },
      ];
      if (val.length === 1 && val.indexOf("PAK") >= 0) {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
        ];
      }
    });
    if (this.$route.query && this.$route.query.workingCountry) {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
          }
        });
      }

      let key = this.$route.query.workingCountry;
      EventBus.$emit("setCountriesfromUrl", key);
      this.workingCountries.forEach((element, index) => {
        if (element.value === key) {
          element.isSelected = true;
        } else element.isSelected = false;
      });
      localStorage.setItem(
        "workingCountries",
        JSON.stringify(this.workingCountries)
      );
    }
    this.JobTypes = JobTypes;
    this.subTruck = TruckTypes;

    if (localStorage.getItem("current-driver-page-status")) {
      this.payload = JSON.parse(
        localStorage.getItem("current-driver-page-status")
      );
    }

    if (localStorage.getItem("DriverJobType")) {
      this.jobType = localStorage.DriverJobType;
      if (this.jobType == 1 && this.workingCountry == "PAK") {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
        ];
      }
      if (this.jobType == 2) {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
        ];
      }
      if (this.jobType == 3) {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
        ];
      }
      this.payload.jobType = this.jobType;
    }
    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `${token}`,
      },
    };
    this.t = config.headers.authorization;

    const url = `${this.constants.apiUrl}/driver/getPreferredLocation`;
    this.axios.get(url).then((response) => {
      if (response.status === 200) {
        response.data.data.forEach((e) => {
          this.preferredLocations.push({ value: e.name, text: e.name });
        });
      }
    });
  },
  components: {
    DriverProfileForm,
    PaginationButtons,
    Loading,
    ErrorBox,
    SuccessDialog,
    ChargesPopUp,
    activityLogs,
    SelectCountry,
  },
  data() {
    return {
      changeTabListing: "drivers",
      transporterUsername: "",
      selectCountryDialog: false,
      availableStatus: [
        { text: "Free", value: "true" },
        { text: "Assigned", value: "false" },
      ],
      status: "",
      showScreen: true,
      operationName: "",
      subTruck: [],
      subTruckType: "",
      workingCountry: null,
      JobTypes: [],
      jobType: "",
      processing: false,
      componentKey: 0,
      success: false,
      message: "",
      error: null,
      t: "",
      search: "",
      search: {
        results: [],
        text: "",
        active: false,
        next: null,
        prev: null,
      },
      searchItems: [],
      searchTypes: "",
      preferredLocations: [],
      preferredLocation: [],
      payload: {},
      totalPages: 0,
      loading: false,
      isNoSearchResult: false,
      pagination: {},
      headers: transporterDriverList,
      items: [],
      driverLoginDialog: false,
    };
  },
  watch: {},
  methods: {
    getTransporter() {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        return;
      }

      this.loading = true;
      let url = transporter.transporterDetails;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        transporterId: this.$route.params.id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.transporterUsername = response.data.data.transporterName;
        },
        (error) => {
          this.x.error = "Failed to Fetch User Details";
        }
      );
    },
    close() {
      this.$router.push(
        `/transporter/edit/${this.$route.params.id}/${this.$route.params._id}/${this.$route.params.country}/true`
      );
    },
    filterByTab(value) {
      this.changeTabListing = value;
    },
    async checkAdd() {
      this.operationName = "register-new-driver";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        if (localStorage.getItem("workingCountries")) {
          this.workingCountries = JSON.parse(
            localStorage.getItem("workingCountries")
          );
          let workingCountry = [];
          this.workingCountries.forEach((key, index) => {
            if (key.isSelected) {
              workingCountry.push(key.value);
            }
          });

          this.$router.push(
            `/transporter/add/${this.$route.params._id}/${this.$route.params.country}`
          );
        }
      } else {
        return;
      }
    },
    checkPermission(operation) {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.$http
        .post(`${rbacUrl}/checkUserPermission`, {
          userId: localStorage.getItem("userId"),
        })
        .then((res) => {
          if (!res.data.data) {
            this.loading = false;
          }
          let old = res.data.data.roles;
          old = old.map((x) => x.role.roleName.toLowerCase());

          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          roles = roles.map((x) => x.role.roleName.toLowerCase());

          old.sort();
          roles.sort();

          if (old.toString() != roles.toString()) {
            this.showScreen = false;
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            Swal.fire({
              title: "Oops!",
              text: "Your permissions have changed .",
              type: "error",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              localStorage.setItem("first-load", true);
              this.$router.replace("/");
              location.reload();
            });
          }
          if (old.toString() == roles.toString()) {
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },

    getText(arr = [], val) {
      let finalEl = null;
      finalEl = arr.find((el) => el.value == val);

      return finalEl ? finalEl.text : "NA";
    },
    getBorderType(type) {
      switch (type) {
        case "Local":
          return "Local";
        case "OutOfBorder":
          return "Cross Country";
      }
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    closeMessage() {
      this.success = false;
      this.componentKey += 1;
    },
    verified(id, status, status1) {
      this.processing = true;
      let Url = transporter.blockAndVerifyDriver;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        driverId: id.toString(),
        accountVerified: status,
        isBlocked: status1.toString(),
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.success = true;
          this.processing = false;
        },
        (error) => {
          this.success = false;
          this.error = error.response.data.message;
          setTimeout(() => {
            this.error = "";
          }, 6000);
        }
      );
    },

    paginationPayload(event) {
      this.loading = event;
      this.payload = event;
      localStorage.removeItem("current-customer-page-status");
    },
    getDefaultUrl() {
      const { serverUrl } = this.constants;
      const { limit = 15, skip = 0 } = this;
      return `${serverUrl}/api/drivers/?limit=${limit}&skip=${skip}`;
    },
    goToUserProfile(driverId, workingCountry) {
      this.$router.push(
        `/transporter/driver-edit/${this.$route.params.id}/${this.$route.params._id}/${driverId}/${workingCountry}/profile`
      );
    },

    searchByType() {
      this.$emit("filters-type", this.searchTypes.abbr);
      this.search.text = "";
      this.searchDriver();
    },
    searchDriverStatus() {
      this.search.active = true;
      if (this.jobType == 2 || this.jobType == 3) {
        this.subTruckType = "";
      }
      if (this.status) {
        this.loading = true;
        this.payload.skip = "0";
        this.payload.limit = "15";
        this.$set(this.payload, "status", this.status);
      }
    },
    searchDriver() {
      if (this.search.text.trim().length > 0) {
        localStorage.setItem(
          "searchDriverType",
          JSON.stringify(this.searchTypes)
        );
        this.payload.searchType = this.searchTypes.abbr;
        this.payload.skip = "0";
        this.payload.limit = "15";
        this.$set(this.payload, "searchText", this.search.text);
        return;
      }
    },

    resetSearch() {
      this.search.active = false;
      this.search.text = "";
      this.searchTypes = "";
      this.subTruckType = "";
      (this.status = ""), (this.jobType = "");
      this.preferredLocation = [];
      this.payload = {
        skip: "0",
        limit: "15",
        transporterId: this.$route.params._id,
        workingCountry: this.$route.params.country,
      };
      localStorage.removeItem("searchDriverPreferredLocation");
      localStorage.removeItem("searchDriverStatus");
      localStorage.removeItem("searchDriverType");
      localStorage.removeItem("searchTransporterDrivertext");
      localStorage.removeItem("DriverJobType");
      localStorage.removeItem("subTruckType");
      if (this.workingCountry != "PAK") {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
        ];
      } else {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
        ];
      }
    },
    callback(res) {
      this.loading = false;
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);
    },
    searchCallback(res) {
      this.loading = false;
      this.items = res.list;
    },
  },
};
</script>

<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: #484848;
  font-weight: bold;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.text-red {
  color: red;
}
.p-16 {
  padding: 0 16px !important;
}
.pull--right {
  float: right;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
