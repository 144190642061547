<template>
  <v-container fluid>
    <v-card raised>
      <loading :active.sync="loading" />
      <v-card-title class="bg-clr">
        <v-btn fab flat @click.native="goback">
          <v-icon class="pointer" v-ripple>arrow_back</v-icon>
        </v-btn>
        <v-layout>
          <v-flex xs3>
            <div class="headline">Driver Details</div>
            <div class="heading muted">{{ driverName }}</div>
          </v-flex>
          <v-flex xs3>
            <v-avatar tile color="#ffa500" class="total avatar">
              <span class="white--text breakword">
                Status
                <span v-if="driverStatus" class="count">Free</span>
                <span v-else class="count">Assignment-{{ assignId }}</span>
              </span>
            </v-avatar>
          </v-flex>
          <v-flex xs3 v-if="!driverStatus">
            <v-avatar tile color="#ffa500" class="total avatar">
              <span class="white--text breakword">
                Driver ID
                <span id="pwd_spn" class="count">{{ driverId }}</span>
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator">
                    <v-icon @click="copy" size="20" color="orange"
                      >content_copy</v-icon
                    ></template
                  >
                  <span class="tooltip">copy to clipboard</span>
                </v-tooltip>
              </span>
            </v-avatar>
          </v-flex>
          <v-flex xs3 v-if="driverStatus">
            <v-avatar tile color="#ffa500" class="total avatar">
              <span class="white--text breakword">
                Driver ID
                <span id="pwd_spn" class="count">{{ driverId }}</span>
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator">
                    <v-icon @click="copy" size="20" color="orange"
                      >content_copy</v-icon
                    >
                  </template>
                  <span class="tooltip">copy to clipboard</span>
                </v-tooltip>
              </span>
            </v-avatar>
          </v-flex>

          <v-flex xs4>
            <v-avatar tile color="#ffa500" class="total avatar">
              <v-btn
                class="messagebtn"
                style="background: orange !important"
                flat
                target="_blank"
                @click="driverProfilePdfDownload()"
                prepend-icon
                color="white darken-1"
              >
                <v-icon size="20" color="white">print</v-icon></v-btn
              >
              <v-btn
                v-if="jobType == 1"
                class="messagebtn"
                style="background: orange !important; padding: 5px"
                flat
                target="_blank"
                @click="driverpdfDownload()"
                prepend-icon
                color="white darken-1"
              >
                <v-icon size="20" color="white">save_alt</v-icon>Download
                Docs</v-btn
              >
            </v-avatar>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text class="nospace content-border m-t-10">
        <v-tabs
          color="grey lighten-3"
          light
          slot="extension"
          slider-color="orange darken-2"
          v-model="active"
        >
          <v-tab
            style="text-transform: none !important"
            href="#tab-1"
            @click="goto(1)"
            >Basic details</v-tab
          >
          <v-tab
            href="#tab-2"
            style="text-transform: none !important"
            @click="goto(2)"
            >Bookings</v-tab
          >
          <v-tab
            href="#tab-3"
            style="text-transform: none !important"
            @click="goto(3)"
            >Earnings</v-tab
          >
        </v-tabs>

        <router-view />
      </v-card-text>
      <v-snackbar
        :timeout="2000"
        top
        style="text-align: center"
        color="orange "
        v-model="textCopy"
        class="white--text"
        v-if="textCopy"
        >{{ textCopy }}</v-snackbar
      >
      <success-dialog
        :content="message"
        :show="success"
        :onclose="closeMessage"
      />
    </v-card>
    <v-dialog v-model="dialog" scrollable max-width="50%">
      <v-form
        ref="form"
        onSubmit="return false;"
        style="width: 100%; height: auto"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Driver Documents </span>
            <v-spacer />
            <div @click="dialog = false">
              <v-icon class="pointer">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text style="height: 100%">
            <v-layout wrap v-if="emiratesIDImageFront || emiratesIDImageBack">
              <v-flex xs6 md4 v-if="emiratesIDImageFront">
                <div>
                  <span class="heading">Emirates Id Front Side</span>

                  <div style="padding-top: 20px">
                    <a
                      :href="emiratesIDImageFront"
                      class="document-link"
                      v-if="
                        emiratesIDImageFront.substr(
                          emiratesIDImageFront.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" />
                    </a>
                    <a
                      :href="emiratesIDImageFront"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </v-flex>
              <v-spacer v-if="emiratesIDImageFront"></v-spacer>
              <v-flex xs6 md4 wrap v-if="emiratesIDImageBack">
                <div>
                  <span class="heading"> Emirates Id Back Side</span>

                  <div style="padding-top: 20px">
                    <a
                      :href="emiratesIDImageBack"
                      class="document-link"
                      v-if="
                        emiratesIDImageBack.substr(
                          emiratesIDImageBack.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" />
                    </a>
                    <a
                      :href="emiratesIDImageBack"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="licenseImage || licenseImageBack">
              <v-flex xs6 md4 wrap v-if="licenseImage">
                <div>
                  <span class="heading"> Driver License Front Side</span>

                  <div style="padding-top: 20px">
                    <a
                      :href="licenseImage"
                      class="document-link"
                      v-if="
                        licenseImage.substr(
                          licenseImage.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" />
                    </a>
                    <a
                      :href="licenseImage"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </v-flex>
              <v-spacer v-if="licenseImage"></v-spacer>
              <v-flex xs6 md4 wrap v-if="licenseImageBack">
                <div>
                  <span class="heading"> Driver License Back Side</span>

                  <div style="padding-top: 20px">
                    <a
                      :href="licenseImageBack"
                      class="document-link"
                      v-if="
                        licenseImageBack.substr(
                          licenseImageBack.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" />
                    </a>
                    <a
                      :href="licenseImageBack"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="vehicleRegImage || vehicleRegImageBack">
              <v-flex xs6 md4 wrap v-if="vehicleRegImage">
                <div>
                  <span class="heading"> Reg. Front Side</span>

                  <div style="padding-top: 20px">
                    <a
                      :href="vehicleRegImage"
                      class="document-link"
                      v-if="
                        vehicleRegImage.substr(
                          vehicleRegImage.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" />
                    </a>
                    <a
                      :href="vehicleRegImage"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </v-flex>
              <v-spacer v-if="vehicleRegImage"></v-spacer>
              <v-flex xs6 md4 wrap v-if="vehicleRegImageBack">
                <div>
                  <span class="heading">Reg. Back Side</span>

                  <div style="padding-top: 20px">
                    <a
                      :href="vehicleRegImageBack"
                      class="document-link"
                      v-if="
                        vehicleRegImageBack.substr(
                          vehicleRegImageBack.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" />
                    </a>
                    <a
                      :href="vehicleRegImageBack"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </v-flex>
            </v-layout>

            <v-layout
              wrap
              v-if="vehicleInsuranceImage || vehicleAuthLetterImage"
            >
              <v-flex xs6 md4 wrap v-if="vehicleInsuranceImage">
                <div>
                  <span class="heading"> Insurance Letter</span>

                  <div style="padding-top: 20px">
                    <a
                      :href="vehicleInsuranceImage"
                      class="document-link"
                      v-if="
                        vehicleInsuranceImage.substr(
                          vehicleInsuranceImage.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" />
                    </a>
                    <a
                      :href="vehicleInsuranceImage"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </v-flex>
              <v-spacer v-if="vehicleInsuranceImage"></v-spacer>
              <v-flex xs6 md4 wrap v-if="vehicleAuthLetterImage">
                <div>
                  <span class="heading"> Auth letter</span>

                  <div style="padding-top: 20px">
                    <a
                      :href="vehicleAuthLetterImage"
                      class="document-link"
                      v-if="
                        vehicleAuthLetterImage.substr(
                          vehicleAuthLetterImage.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" />
                    </a>
                    <a
                      :href="vehicleAuthLetterImage"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="passPortImage || nationalIdImage">
              <v-flex xs6 md4 wrap v-if="passPortImage">
                <div>
                  <span class="heading"> Passport Image</span>

                  <div style="padding-top: 20px">
                    <a
                      :href="passPortImage"
                      class="document-link"
                      v-if="
                        passPortImage.substr(
                          passPortImage.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" />
                    </a>
                    <a
                      :href="passPortImage"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </v-flex>
              <v-spacer v-if="passPortImage"></v-spacer>
              <v-flex xs6 md4 wrap v-if="nationalIdImage">
                <div>
                  <span class="heading">National Id Image</span>

                  <div style="padding-top: 20px">
                    <a
                      :href="nationalIdImage"
                      class="document-link"
                      v-if="
                        nationalIdImage.substr(
                          nationalIdImage.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img src="../../assets/pfd.png" />
                    </a>
                    <a
                      :href="nationalIdImage"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        src="../../assets/docimg.webp"
                        height="72"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="dialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import TransporterDriverProfile from "./TransporterDriverProfile";
import SuccessDialog from "@/components/Common/SuccessDialog";
import TransporterDriverDetailAssignments from "./TransporterDriverDetailAssignments";
import { StorageKeys, CancellationType } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
//import { TruckTypes } from "../constants";
import "vue-loading-overlay/dist/vue-loading.min.css";
export default {
  created() {
    EventBus.$on("countryChanged", (val) => {});
    const { id = null } = this.$route.params;
    if (id) {
      this.driverId = id;
    }

    const url = this.$route.path;
    // main url

    if (
      url.match(
        /^\/transporter\/driver-edit\/[0-9]+\/[a-z0-9]+\/[0-9]+\/[A-Z]+$/
      )
    ) {
      this.active = "tab-1";
      this.$router.push(
        `/transporter/driver-edit/${this.$route.params.transporterId}/${this.$route.params._id}/${this.driverId}/${this.$route.params.country}/profile`
      );
    } else if (
      url.match(
        /^\/transporter\/driver-edit\/[0-9]+\/[a-z0-9]+\/[0-9]+\/[A-Z]+\/profile$/
      )
    ) {
      this.active = "tab-1";
    } else if (
      url.match(
        /^\/transporter\/driver-edit\/[0-9]+\/[a-z0-9]+\/[0-9]+\/[A-Z]+\/assignments$/
      )
    ) {
      this.active = "tab-2"; // assignments url
      this.axios
        .get(`${this.constants.serverUrl}/api/drivers/detail/${this.driverId}`)
        .then(
          (response) => {
            const { driversInfo } = response.data.data;
            this.driverData = driversInfo;
            this.jobType = driversInfo.jobType;
            this.driverStatus = driversInfo.availabilityStatus;

            this.driverName =
              driversInfo.firstName + " " + driversInfo.lastName;
          },
          (error) => {}
        );
    } else {
      this.active = "tab-3";
    }
    this.$eventBus.$on("driver-Detail", ({ driversInfo }) => {
      this.driverStatus = driversInfo.availabilityStatus;
      this.driverData = driversInfo;
      this.jobType = driversInfo.jobType;
      this.assignId = driversInfo.assignId;
    });

    this.$eventBus.$on("on-driver-profile-load", ({ name }) => {
      this.driverName = name;
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });
  },
  components: {
    TransporterDriverProfile,
    SuccessDialog,
    TransporterDriverDetailAssignments,
    Loading,
  },
  data() {
    return {
      operationName: "",
      message: "Text is copied",
      dialog: false,
      active: null,
      success: false,
      driverId: null,
      textCopy: null,
      assignId: null,
      loading: false,
      driverName: null,
      driverStatus: null,
      driverData: null,
      jobType: 1,
    };
  },
  methods: {
    async check() {
      this.operationName = "assignment-history-driver";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.goto(2);
      } else {
        this.active = "tab-1";
        return;
      }
    },
    closeMessage() {
      this.success = false;
      this.componentKey += 1;
    },
    copy() {
      var copyText = document.getElementById("pwd_spn");
      var textArea = document.createElement("textarea");
      textArea.value = copyText.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("Copy");
      textArea.remove();
      this.textCopy = "Text is copied";
    },
    next(active) {
      this.active = active;
    },
    setDriver(driverName) {
      this.driverName = driverName;
    },
    goback() {
      this.active = "tab-1";

      this.$router.push(
        `/transporter/driver/${this.$route.params.transporterId}/${this.$route.params._id}/${this.$route.params.country}`
      );
    },
    goto(num) {
      if (num === 1) {
        this.$router.push(
          `/transporter/driver-edit/${this.$route.params.transporterId}/${this.$route.params._id}/${this.driverId}/${this.$route.params.country}/profile`
        );
      } else if (num === 2) {
        this.$router.push(
          `/transporter/driver-edit/${this.$route.params.transporterId}/${this.$route.params._id}/${this.driverId}/${this.$route.params.country}/assignments`
        );
      } else {
        this.$router.push(
          `/transporter/driver-edit/${this.$route.params.transporterId}/${this.$route.params._id}/${this.driverId}/${this.$route.params.country}/earnings`
        );
      }
    },
    goToDriverDoc() {
      this.$router.push(`/drivers/${this.driverId}/driver-doc`);
    },
    driverProfilePdfDownload() {
      this.$eventBus.$emit("on-load");
      let url = "/dmsAdmin/newcreateDriverPdf";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let jobType = "Land";
      if (this.driverData.jobType == 1) {
        jobType = "Land";
      }
      if (this.driverData.jobType == 2) {
        jobType = "Air";
      }

      if (this.driverData.jobType == 3) {
        jobType = "Sea";
      }

      let body = {
        driverId: this.driverId,
        workingCountry: this.driverData.workingCountry,
        driverType: jobType,
      };
      this.loading = true;
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          if (response.data) {
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);

            // Set the HREF to a Blob representation of the data to be downloaded
            a.href = response.data.data;
            a.download = "documents" + this.driverId;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        },
        (error) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          this.textCopy = error.response.data.message;
        }
      );
    },
    driverpdfDownload() {
      this.$eventBus.$emit("on-load");
      let url = "/assignments/driverDocuments";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { driverId: this.driverId };
      this.loading = true;
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          console.log(response.data);

          console.log(response.data);

          if (response.data.data[0].emiratesIDImageBack) {
            this.emiratesIDImageBack =
              response.data.data[0].emiratesIDImageBack;
          }
          if (response.data.data[0].emiratesIDImageFront) {
            this.emiratesIDImageFront =
              response.data.data[0].emiratesIDImageFront;
          }
          if (response.data.data[0].licenseImage) {
            this.licenseImage = response.data.data[0].licenseImage;
          }
          if (response.data.data[0].licenseImageBack) {
            this.licenseImageBack = response.data.data[0].licenseImageBack;
          }
          if (response.data.data[0].nationalIdImage) {
            this.nationalIdImage = response.data.data[0].nationalIdImage;
          }
          if (response.data.data[0].passPortImage) {
            this.passPortImage = response.data.data[0].passPortImage;
          }
          if (response.data.data[0].vehicleAuthLetterImage) {
            this.vehicleAuthLetterImage =
              response.data.data[0].vehicleAuthLetterImage;
          }

          if (response.data.data[0].vehicleInsuranceImage) {
            this.vehicleInsuranceImage =
              response.data.data[0].vehicleInsuranceImage;
          }
          if (response.data.data[0].vehicleRegImage) {
            this.vehicleRegImage = response.data.data[0].vehicleRegImage;
          }
          if (response.data.data[0].vehicleRegImageBack) {
            this.vehicleRegImageBack =
              response.data.data[0].vehicleRegImageBack;
          }

          // if (response.data) {
          //   const a = document.createElement("a");
          //   a.style.display = "none";
          //   document.body.appendChild(a);

          //   // Set the HREF to a Blob representation of the data to be downloaded
          //   a.href = response.data.data;
          //   a.download = "documents" + this.driverId;
          //   a.target = "_blank";
          //   document.body.appendChild(a);
          //   a.click();
          //   window.URL.revokeObjectURL(a.href);
          //   document.body.removeChild(a);
          // }
          this.dialog = true;
        },
        (error) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          this.textCopy = error.response.data.message;
        }
      );
    },
  },
};
</script>

<style>
.avatar-width {
  min-width: 280px !important;
}
.remove-link-style {
  text-decoration-style: none !important;
}
.m-t-10 {
  margin-top: 10px;
}
</style>
